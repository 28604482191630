import React from 'react';

export const Row = (props: React.PropsWithChildren): JSX.Element => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 20
    }}
  >
    {props.children}
  </div>
);
