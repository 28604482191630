import React from 'react';
import { styled } from '@hiyllo/ux/styled';

export const Table = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    background: $theme.background3,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: $theme.background3,
    borderRadius: 10,
    overflow: 'hidden'
}));

export const TableRow = styled('div', ({ $theme }) => ({
    background: $theme.background1,
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    gap: 10,
    cursor: 'pointer'
}));
