import React from 'react';
import { Button } from '@hiyllo/ux/button';
import { useNavigate } from 'react-router-dom';
import { InlineLink } from '@hiyllo/ux/inline-link';
import { EmptySplash } from '@hiyllo/ux/empty-splash';
import { LoadingSpinner } from '@hiyllo/ux/loading-spinner';
import { faEmptySet, faExclamationTriangle, faSparkles } from '@fortawesome/pro-light-svg-icons';

import * as GetFederationProductsBP from '../../../blueprints/products/get-federation-products';

import { FlexCenter } from '../../common';
import { Table, TableRow } from '../table';
import { seamlessClient } from '../../../seamless';
import { Product, ProductKind } from '../../../types/product';
import { Typography } from '@hiyllo/ux/typography';

export const ProductTable = React.memo(function ProductTable (props: {products: Product[]}): JSX.Element {
  const navigate = useNavigate();

  return (
    <Table>
      {props.products.map(product => (
        <TableRow onClick={() => navigate(`/products/${product.uuid}`)} key={product.uuid}>
          <div style={{ flexGrow: 1 }}>
            <b>
              {product.name}
            </b>
          </div>
          <div>
            <InlineLink label={`${product.slug}.hiyllo.${product.kind.type}`} href={`https://${product.slug}.hiyllo.${product.kind.type}`}/>
          </div>
        </TableRow>
      ))}
    </Table>
  );
});

export const ProductsList = React.memo(function ProductsList(props: {kind: ProductKind}): JSX.Element {
  const productsQuery = seamlessClient.useQuery<GetFederationProductsBP.Plug>(GetFederationProductsBP, null);
  const navigate = useNavigate();

  const products = React.useMemo(() => {
    return (productsQuery.data?.products.filter(p => p.kind.type === props.kind)) ?? [];
  }, [productsQuery.data?.products, props.kind]);

  if (productsQuery.isLoading) {
    return <><FlexCenter><LoadingSpinner/></FlexCenter></>;
  }

  if (productsQuery.isError) {
    return (
      <>
        <EmptySplash
          icon={faExclamationTriangle}
          label='Unable to load products'
          hint={productsQuery.error.message}
        />
      </>
    );
  }

  if (products.length === 0) {
    if (props.kind === ProductKind.support) {
      return (
        <EmptySplash
          icon={faSparkles}
          label={'We\'re excited about Support too!'}
          hint="Support will be available to start using soon"
        />
      );
    }

    return (
      <>
        <EmptySplash
          icon={faEmptySet}
          label={props.kind === ProductKind.work ? "You don't have any workspaces yet..." : "You don't have any support centers yet..."}
          hint={props.kind === ProductKind.work ? 'Ready to bring your team some bliss?' : 'Ready to bring your customers some bliss?'}
          afterElement={
            <div style={{ paddingTop: 5 }}>
              <Button
                onClick={() => navigate(`/products/create/${props.kind}`)}
                label={props.kind === ProductKind.work ? 'Create new Workspace' : 'Create new Support Center'}
              />
            </div>
          }
        />
      </>
    );
  }

  return (
    <>
      <Typography.HeaderRow style={{ justifyContent: 'space-between' }}>
        <Typography.SubHeader>Your Workspaces</Typography.SubHeader>
        <Button isSecondary onClick={() => navigate(`/products/create/${props.kind}`)} label={`Create new ${props.kind === ProductKind.work ? 'Workspace' : 'Support Center'}`} />
      </Typography.HeaderRow>
      <div style={{ height: 10 }}/>
      <ProductTable products={products}/>
    </>
  );
});
