import { styled } from '@hiyllo/ux/styled';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { seamlessClient } from '../../seamless';

import * as GetFederationBP from '../../blueprints/federation/get-federation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faBuilding, faMoneyBillTransfer } from '@fortawesome/pro-light-svg-icons';

const NavigationBarContainer = styled('div', ({ $theme }) => ({
    background: $theme.background3,
    height: 50,
    paddingLeft: 7.5,
    paddingRight: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10
}));

const Title = styled('div', ({ $theme }) => ({
    color: $theme.foreground,
    fontWeight: 'bold',
    fontSize: 17.5
}));

const SecondaryTitle = styled('div', ({ $theme }) => ({
    fontSize: 15
}));

const Group = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    paddingLeft: 10,
    paddingRight: 5,
    userSelect: 'none'
});

const PressableGroup = styled<'div', {$active?: boolean}>('div', ({ $theme, $active }) => ({
    userSelect: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: $theme.foreground,
    opacity: $active ? 1 : 0.8,
    gap: 5,
    height: 32.5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 5,
    cursor: 'pointer',
    transition: 'background ease 0.15s',
    textDecoration: 'none !important',
    ':hover': {
        opacity: 1,
        background: $theme.midground1
    }
}));

export const NavigationBar = React.memo(function NavigationBar(): JSX.Element {
    const location = useLocation();
    const federationQuery = seamlessClient.useQuery<GetFederationBP.Plug>(GetFederationBP, null);

    const federation = federationQuery.data?.federation ?? null;

    return (
        <div style={{ boxShadow: 'rgba(0, 0, 0, 0.5) 0px 20px 20px -10px', zIndex: 10 }}>
            <NavigationBarContainer>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <Group>
                        <img src="https://cdn.hiyllo.net/logo/hiyllo/icon-white.png" style={{ height: 20, width: 20 }}/>
                        <Title>
                            Hiyllo Business
                        </Title>
                    </Group>
                </Link>
                <Link to="/work" style={{ textDecoration: 'none' }}>
                    <PressableGroup $active={location.pathname.startsWith('/work')}>
                        <img src={'https://cdn.hiyllo.net/logo/work/icon-gradient.png'} style={{ height: 15, display: location.pathname.startsWith('/work') ? '' : 'none' }}/>
                        <img src={'https://cdn.hiyllo.net/logo/work/icon-white.png'} style={{ height: 15, display: location.pathname.startsWith('/work') ? 'none' : '' }}/>
                        <SecondaryTitle>
                            Workspaces
                        </SecondaryTitle>
                    </PressableGroup>
                </Link>
                <Link to="/support" style={{ textDecoration: 'none' }}>
                    <PressableGroup>
                        <img src={'https://cdn.hiyllo.net/logo/support/color-icon.png'} style={{ height: 15, display: location.pathname.startsWith('/support') ? '' : 'none' }}/>
                        <img src={'https://cdn.hiyllo.net/logo/support/white-icon.png'} style={{ height: 15, display: location.pathname.startsWith('/support') ? 'none' : '' }}/>
                        <SecondaryTitle>
                            Support Centers
                        </SecondaryTitle>
                    </PressableGroup>
                </Link>
                <div style={{ flexGrow: 1 }}/>
                <Link to="/billing" style={{ textDecoration: 'none' }}>
                    <PressableGroup>
                        <FontAwesomeIcon icon={faMoneyBillTransfer} style={{ fontSize: 15, color: location.pathname.startsWith('/billing') ? '#4caf50' : undefined }}/>
                        <SecondaryTitle>
                            Billing
                        </SecondaryTitle>
                    </PressableGroup>
                </Link>
                {federation != null ?
                    <Group>
                        <FontAwesomeIcon icon={faBriefcase} color="white" style={{ fontSize: 12.5 }}/>
                        <SecondaryTitle style={{ color: 'white' }}>{federation.name}</SecondaryTitle>
                    </Group>
                : null}
            </NavigationBarContainer>
        </div>
    );
});
