import React from 'react';
import { Regal } from './regal';

/**
 * Being migrated from https://www.hiyllo.business/work/legal/terms-of-service.pdf
 */

export const UserTermsOfUse = React.memo(function UserTermsOfUse(): JSX.Element {
    return (
        <Regal.Root>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                <img src="https://cdn.hiyllo.net/logo/hiyllo/icon-white.png" style={{ height: 30 }}/>
                <div style={{ fontSize: 30, fontWeight: 'bold' }}>
                    Hiyllo Business
                </div>
            </div>
            <div>
                <h2>User Terms of Use</h2>
            </div>
            <div>Last Updated: October 28th, 2024</div>
            <div>
                <p>
                    These User Terms of Use governs your use of Hiyllo Business, a collection of digital tools and services (collectively &quot;Product&quot;), operated and provided by Hiyllo Inc (&quot;Hiyllo&quot;). These User Terms of Use apply to you as a user of any Hiyllo Business product and/or service. The entity with which Hiyllo entered into a relationship with concerning the purchase of the Hiyllo Business products and/or services (&quot;Admin&quot;) is responsible for answering any questions you may have regarding these User Terms of Use.
                </p>
            </div>
            <Regal.Content title="Governing Law">
                This agreement and any disputes arising from it are governed by the laws and jurisdiction of Mercer County, New Jersey, United States of America.
            </Regal.Content>
            <Regal.Content title="At Will">
                <Regal.Content title='Term'>
                    Your usage of the Product and Hiyllo&apos;s provision of such is at will, and both Hiyllo and the Admin reserve the right to terminate your usage at any time
                </Regal.Content>
                <Regal.Content title='Termination'>
                    Upon termination, neither Hiyllo nor the Admin shall have any obligations to you, you shall not be entitled to any notice before termination, and you shall not have the rights to any data stored or otherwise contained within any Product.
                </Regal.Content>
            </Regal.Content>
            <Regal.Content title="Acceptable Use">
                <Regal.Content title='Prohibited Activities'>
                    You agree to abide by the following terms when using the Product:
                    <Regal.Content>
                        The Product may not be used for the sale of drugs, narcotics, pharmaceuticals, or other controlled substances
                    </Regal.Content>
                    <Regal.Content>
                        The Product may not be used for the sale of alcohol or any other products that require the purchaser to be above a certain age
                    </Regal.Content>
                    <Regal.Content>
                        The Product may not be used to sell weapons, knives, pepper spray, tasers, firearms, etc
                    </Regal.Content>
                    <Regal.Content>
                        The Product may not be used to sell illegal items or merchandise, whether physical items or digital content
                    </Regal.Content>
                    <Regal.Content>
                        The Product may not be used for the sharing of illegal obtained information or media
                    </Regal.Content>
                    <Regal.Content>
                        The Product may not be used to share, circulate, or reveal personal information of nonpublic figures without their consent (the action commonly known as “Doxing” or &quot;Doxxing&quot;)
                    </Regal.Content>
                    <Regal.Content>
                        The Product may not be used to harass or intimidate individuals, or to organize or facilitate conspiracy to harass or intimidate individuals
                    </Regal.Content>
                    <Regal.Content>
                        The Product  may not be used in a way where the majority of END USERS are intended to be under the age of 18
                    </Regal.Content>
                </Regal.Content>
                <Regal.Content title='Restricted Activities'>
                    You agree to abide by the following terms when using the Product, unles you have been granted a written waiver from Hiyllo:
                    <Regal.Content>
                        The Product may not be used for the sale of pornographic or sexual content
                    </Regal.Content>
                    <Regal.Content>
                        The Product may not be used to share, store, or otherwise interact with Social Security Numbers or Federal Employee Identification Numbers
                    </Regal.Content>
                    <Regal.Content>
                        The Product may not be used to sell cryptocurrencies, stock, shares, or other assets intended as a hold of value
                    </Regal.Content>
                </Regal.Content>
            </Regal.Content>
            <Regal.Content title="Limitations of Liability">
                You hereby acknowledge that you may not hold Hiyllo liable for any loss incurred by use of the Product. You also agrees that Hiyllo provides no guarantee or warranty of quality or legitimacy for your usage of the Product.
            </Regal.Content>
            <Regal.Content title="Disclaimer of Warranty">
            In no event shall Hiyllo be held liable or responsible for any damages, losses, or
consequences, whether direct, indirect, special, incidental, or consequential, arising from or
related to the unauthorized access, hacking, or data breach of the Product or any
unavailability, downtime, or interruption of the Product. Hiyllo does not warrant
that the Product will be error-free, uninterrupted, or impervious to security breaches, nor
does it make any warranty as to the results that may be obtained from the use of THE
PRODUCT. The CUSTOMER acknowledges and agrees that the use of the Product is at
their sole risk and that Hiyllo is providing the Product on an &quot;AS IS&quot; and &quot;AS
AVAILABLE&quot; basis, without warranties of any kind, either expressed or implied, including but
not limited to warranties of merchantability, fitness for a particular purpose, or noninfringement.
            </Regal.Content>
            <Regal.Content title="Ownership of Data">
            Hiyllo hereby asserts that all data uploaded into, posted on, inserted into, or
otherwise made available on or within the Product is owned by the Admin. However, you
acknowledge that in the event of a violation of law or a violation of the Acceptable Use part of
this agreement, Hiyllo at their discretion may make unavailable any or all of the data
contained within the Product. In the event that use of the Product violates the law of
any applicable jurisdictions, Hiyllo reserves the right to share and/or disclose the
data contained within the Product to law enforcement authorities.
            </Regal.Content>
            <Regal.Content title="Indemnification">
            You shall indemnify, defend, and hold harmless Hiyllo, its officers, directors,
employees, and agents from and against any and all claims, liabilities, losses, damages, costs,
or expenses (including reasonable attorneys&apos; fees) arising out of or in connection with (a) your
use of the Product; (b) any violation or breach of this Agreement by yourself; (c) any
infringement or misappropriation of any intellectual property rights, or violation of any
applicable laws, rules, or regulations by you or the Admin; or (d) any gross negligence or
willful misconduct by you or the Admin.
            </Regal.Content>
            <Regal.Content title="Dispute Resolution">
            In the event of any dispute, claim, question, or disagreement arising from or relating to this
Agreement, the parties shall use their best efforts to settle such disputes, claims, questions, or
disagreements through good faith negotiation. If the dispute cannot be resolved through
negotiation within thirty (30) days, either party may request that the dispute be resolved by
binding arbitration administered by the American Arbitration Association (AAA) in accordance
with its Commercial Arbitration Rules. The arbitration shall be held in Mercer County, New
Jersey, and judgment on the award rendered by the arbitrator may be entered in any court
having jurisdiction thereof. The prevailing party in any arbitration or legal action shall be entitled
to recover reasonable attorneys&apos; fees and costs from the other party
            </Regal.Content>
        </Regal.Root>
    );
});
