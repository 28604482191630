/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { BillingRate, PlanKind } from "../types/subscription";

export const WORKSPACE_STANDARD_PLAN: BillingRate = {
    costPerUser: 1999,
    includedGBPerUser: 25,
    costCentsPerGB: 5,
    type: "work"
};

export const SUPPORT_BASIC_PLAN: BillingRate = {
    type: "support",
    variant: "basic",
    baseCostE2: 1500,
    costPerUser: 100,
    includedGB: 25,
    costCentsPerGB: 5,
    customDomainCostE2: 500,
    includedVisitorsK: 10,
    costPer1KUniqueVisitorE2: 99,
    includedEmailsPerMonthBase: 5000,
    includedEmailsPerMonthPerUser: 500,
    costPer1kAddiitionalEmailE2: 100
}

export const SUPPORT_PRO_PLAN: BillingRate = {
    type: "support",
    variant: "pro",
    baseCostE2: 3000,
    costPerUser: 100,
    includedGB: 25,
    costCentsPerGB: 5,
    customDomainCostE2: 0,
    includedVisitorsK: 10,
    costPer1KUniqueVisitorE2: 99,
    includedEmailsPerMonthBase: 5000,
    includedEmailsPerMonthPerUser: 500,
    costPer1kAddiitionalEmailE2: 100
}

export const PlansMap: {[k in PlanKind]:BillingRate} = {
    [PlanKind.work_standard]: WORKSPACE_STANDARD_PLAN,
    [PlanKind.support_basic]: SUPPORT_BASIC_PLAN,
    [PlanKind.support_pro]: SUPPORT_PRO_PLAN,
    [PlanKind.support_internal]: SUPPORT_BASIC_PLAN,
}