import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import './index.css';
import { ThemeProvider, ThemeType } from '@hiyllo/ux/theme';
import { HiylloTokyoThemeDark } from '@hiyllo/ux/tokyo-theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FontAwesomeIconCtx } from '@hiyllo/ux/platform';
import { EnvironmentProvider } from '@hiyllo/omni-continuity';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const Theme: ThemeType = {
  ...HiylloTokyoThemeDark,
  buttonForeground: 'white',
  buttonBackground: 'linear-gradient(to right, rgb(254 2 254), rgb(254 143 1))',
  background1: '#101014',
  background2: '#17161B',
  background3: '#201f24',
  midground: '#37363a',
  foreground: '#ffffff',
  foregroundInactive: '#999',
  surfaceShadow: 'rgb(255 255 255 / 13%) 0px 0px 0px -4px',
  colorScheme: 'dark'
};

const usp = new window.URLSearchParams(window.location.search);
const affiliate = usp.get('a');
if (affiliate) {
  window.localStorage.setItem('affiliate', affiliate);
}

root.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <EnvironmentProvider environment={{ isSolo: false }}>
        {/* @ts-expect-error --- */}
        <FontAwesomeIconCtx.Provider value={FontAwesomeIcon}>
          <App />
        </FontAwesomeIconCtx.Provider>
      </EnvironmentProvider>
    </ThemeProvider>
  </React.StrictMode>
);
