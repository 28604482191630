import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { styled } from '@hiyllo/ux/styled';
import { MoopsyError } from '@moopsyjs/core';
import { LoadingSpinner } from '@hiyllo/ux/loading-spinner';

const FullWidthCenter = styled('div', {
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

export const GrowLoading = React.memo(function GrowLoading (): JSX.Element {
  return (
    <FullWidthCenter>
      <LoadingSpinner/>
    </FullWidthCenter>
  );
});

export const ErrorView = React.memo(function ErrorView (props: {error: MoopsyError}): JSX.Element {
  return (
    <FullWidthCenter>
      <div style={{ color: 'red' }}>{props.error.message}</div>
    </FullWidthCenter>
  );
});
