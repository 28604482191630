/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { ProductStatusEnum } from "../../types/product";

export type ParamsType = {
    productUUID: string;
    status: ProductStatusEnum;
};
export type ResponseType = void;
export const Endpoint = 'products/set-product-status';
export const Method = 'POST';

export interface Plug {
    params: ParamsType
    response: ResponseType
    method: typeof Method
    endpoint: typeof Endpoint
};export const paramsSchema = {"type":"object","properties":{"productUUID":{"type":"string"},"status":{"$ref":"#/definitions/ProductStatusEnum"}},"required":["productUUID","status"],"definitions":{"ProductStatusEnum":{"enum":["active","restricted","suspended","terminated"],"type":"string"}},"$schema":"http://json-schema.org/draft-07/schema#"}