/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { Invoice } from '../../types/invoices';
import type { PaymentMethodType } from '../../types/payment-methods';
import { Product } from '../../types/product';
import { SubscriptionType } from '../../types/subscription';

export interface EnhancedPaymentMethod extends PaymentMethodType {
    brand: string;
    last4: string;
    expMonth: number;
    expYear: number;
}

export type ParamsType = null;
export type ResponseType = {
    paymentMethods: EnhancedPaymentMethod[];
    subscriptions: Array<{
        subscription: SubscriptionType;
        product: Product;
    }>;
    recentInvoices: Invoice[];
};
export const Endpoint = 'billing/get-billing-details';
export const Method = 'GET';

export interface Plug {
    params: ParamsType
    response: ResponseType
    method: typeof Method
    endpoint: typeof Endpoint
};export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}