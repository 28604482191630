import { styled } from '@hiyllo/ux/styled';
import React from 'react';
import { NavigationBar } from './components/navigation-bar';

export const PageContainer = styled('div', ({ $theme }) => ({
  background: $theme.background1,
  padding: 20,
  width: 'calc(100% - 40px)',
  height: '100%',
  color: 'white',
  flexGrow: 1,
  overflowY: 'auto'
}));

const Navbar = <NavigationBar/>;

export const PageWithNavbar = React.memo(function PageWithNavbar(props: React.PropsWithChildren): JSX.Element {
  return (
    <div style={{ height: '100vh', width: '100%', display: 'flex', flexDirection: 'column' }}>
      {Navbar}
      <PageContainer>
        {props.children}
      </PageContainer>
    </div>
  );
});

export const FlexCenter = styled('div', {
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
});

export const Header = styled('div', {
  fontSize: 34,
  marginBottom: 7.5,
  fontWeight: 'bold',
  fontFamily: 'hiyllo'
});

export const Label = styled('div', {
  fontSize: 14,
  marginBottom: 7.5
});
