/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { UsageType } from "./usage";

export enum ProductKind {
    work = "work",
    support = "support",
}

export enum WorkVariantEnum {
    standard = "standard",
}

export enum SupportVariantEnum {
    internal = "internal",
    basic = "basic",
    pro = "pro",
}

export type ProductKindType = {
    type: "work",
    variant: WorkVariantEnum;
} | {
    type: "support",
    variant: SupportVariantEnum;
}

export enum ProductStatusEnum {
    /**
     * Up and running!
     */
    active = "active",
    /**
     * Service is restricted, certain actions will be disabled.
     * Typically due to non-payment
     */
    restricted = "restricted",
    /**
     * All functionality has been disabled.
     * Typically due to non-payment
     */
    suspended = "suspended",
    /**
     * Service has been terminated. Data is or will soon be deleted
     * from the system.
     */
    terminated = "terminated",
}

export interface Product {
    kind: ProductKindType;
    uuid: string;
    /**
     * (x).hiyllo.work | (x).hiyllo.support
     */
    slug: string;
    name: string;
    subscriptionUUID: string;
    /**
     * Federation Slug
     */
    federation: string;
    status: ProductStatusEnum;
    /**
     * HiylloID of user who initially created, this user will be the initial superadmin as well
     */
    creator: string;

    usage: null | UsageType;
}