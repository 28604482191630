import React from 'react';
import { Regal } from './regal';

/**
 * Being migrated from https://www.hiyllo.business/work/legal/terms-of-service.pdf
 */

export const TermsOfService = React.memo(function TermsOfService(): JSX.Element {
    return (
        <Regal.Root>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                <img src="https://cdn.hiyllo.net/logo/hiyllo/icon-white.png" style={{ height: 30 }}/>
                <div style={{ fontSize: 30, fontWeight: 'bold' }}>
                    Hiyllo Business
                </div>
            </div>
            <div>
                <h2>Hiyllo Business Terms of Service</h2>
            </div>
            <div>Last Updated: November 4th, 2024</div>
            <div>
                <p>
                    These Terms of Service (the “Terms of Service” or “Agreement”) set forth the terms and conditions
    governing the relationship between Hiyllo Inc, a Delaware corporation with a principal place of business
    at 3495 US Highway 1 STE 34 #1173, Princeton, NJ 08540. (“Hiyllo”) and the customer identified in the
    applicable Customer Order (as defined below) (“Customer”) relating to the services (the “Services”)
    provided by Hiyllo through the web platform and mobile apps (the “Hiyllo WorkSpace”). Hiyllo and
    Customer are individually referred to as a “Party” and collectively as the “Parties.”
                </p>
            </div>
            <Regal.Content title="Services">
                <Regal.Content title='Scope of Services'>
                    Subject to the terms and conditions of this Agreement, Hiyllo shall provide
the Services agreed to by the Parties in a sales order through the Hiyllo WorkSpace Platform (a
“Customer Order”).
                </Regal.Content>
                <Regal.Content title='Platform Terms of Use and Privacy Policy'>
                    By entering into this Agreement, Customer agrees to&nbsp;
{/* to the Hiyllo Business User Terms of Use located at <a href="https://www.hiyllo.business/user-terms-of-use" style={{ color: 'white' }}>https://www.hiyllo.business/user-terms-of-use</a> (“Terms of Use”), and */}the Hiyllo Privacy Policy
located at <a href="https://www.hiyllo.business/privacy-policy" style={{ color: 'white' }}>https://www.hiyllo.business/privacy-policy</a> (“Privacy Policy”), which are incorporated herein by reference.
Capitalized terms not defined in this Agreement shall have the meaning set forth in the Terms of Use
and Privacy Policy. To the extent the Terms of Use or Privacy Policy are inconsistent with the Terms
of Service, the Terms of Service shall control. Customer shall make sure that all users of the Services
agree to the Terms of Use and Privacy Policy.
                </Regal.Content>
                <Regal.Content title='Changes to Services'>
                    Hiyllo reserves the right, in its sole discretion, to make any changes to the
Services that it deems necessary or useful to: (a) maintain or enhance the quality or delivery of
Hiyllo’s Services to its customers, the competitive strength of or market for Hiyllo’s Services or the
Services’ cost efficiency or performance; or (b) to comply with applicable law
                </Regal.Content>
                <Regal.Content title='Savings Clause'>
                    Hiyllo’s failure to perform, or delay in performing, its responsibilities under this
Agreement (including any Customer Order) shall be excused if and to the extent that Hiyllo
demonstrates that such non-performance or delay is caused by: (i) the failure of Customer or
Customer personnel to perform their obligations under this Agreement; (ii) any act performed or
omitted by Hiyllo or Hiyllo personnel at the request of Customer; (iii) any act performed by Customer
or Customer personnel to the extent that such act was not otherwise agreed by the Parties; or (iv)
system failure caused by a malicious third-party technical attack causing a sudden, unplanned
increase in network activity above the threshold capacity of Hiyllo systems.
                </Regal.Content>
                <Regal.Content title='Third Party Services'>
                    Hiyllo may make certain third-party products or services available to
Customer, or may make its Services available to be used by Customer within certain third-party
products or services (all third-party products and services collectively referred to as “Third-Party
Services”), for which Hiyllo makes no representations, warranties or indemnities and will have no
other liability hereunder, notwithstanding anything contained in this Agreement. Customer’s
exclusive remedy with regard to Third-Party Services shall be against the Third-Party.
                </Regal.Content>
            </Regal.Content>
            <Regal.Content title="Access and Use, Customer Restrictions.">
                <Regal.Content title='Access and Use'>
                    Subject to and conditioned on Customer’s and its Authorized Users’ compliance
with the terms and conditions of this Agreement, Hiyllo hereby grants Customer a non-exclusive,
non-transferable right to access and use the Services during the term of the applicable Customer
Order solely for use by Authorized Users in accordance with the terms and conditions herein. Such
use is limited to Customer’s internal use. “Authorized User” means Customer’s employees,
consultants, contractors, and agents (i) who are authorized by Customer to access and use the Services
under the rights granted to Customer pursuant to this Agreement and (ii) for whom access to the
Services has been purchased.
                </Regal.Content>
                <Regal.Content title='Reservation of Rights'>
                    Nothing in this Agreement grants any right, title or interest in or to
(including any license under) any intellectual property rights in or relating to, the Services, the Hiyllo
WorkSpace Platform, or third-party materials, whether expressly, by implication, estoppel or
otherwise. All right, title and interest in and to the Services, the Hiyllo WorkSpace Platform, and
third-party materials are and will remain with Hiyllo and the respective rights holders in the thirdparty materials.
                </Regal.Content>
                <Regal.Content title="Authorization Limitations and Restrictions">
                    Customer shall not, and shall not permit any other
person to, access or use the Services except as expressly permitted by this Agreement and, in the case
of third-party materials, the applicable third-party license agreement. For purposes of clarity and
without limiting the generality of the foregoing, Customer shall not, except as this Agreement
expressly permits:
                    <Regal.OL>
                        <div>
                            copy, modify or create derivative works or improvements of the Services;
                        </div>
                        <div>
                            rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer or otherwise make available any Services to any person, including on or in connection with the internet or any timesharing, service bureau, software as a service, cloud or other technology or service;
                        </div>
                        <div>
                            reverse engineer, disassemble, decompile, decode, adapt or otherwise attempt to derive or gain access to the source code of the Services, in whole or in part;
                        </div>
                        <div>
                        bypass or breach any security device or protection used by the Services or access or use the
Services other than by an Authorized User through the use of his or her own then valid access
credentials;
                        </div>
                        <div>
                            input, upload, transmit or otherwise provide to or through the Services or Hiyllo WorkSpace
Platform, any information or materials that are unlawful or injurious, or contain, transmit or activate
any harmful code;
                        </div>
                        <div>
                            damage, destroy, disrupt, disable, impair, interfere with or otherwise impede or harm in any
manner the Services, the Hiyllo WorkSpace Platform or Hiyllo’s provision of services to any third
party, in whole or in part;
                        </div>
                        <div>
                            remove, delete, alter or obscure any trademarks, specifications, documentation, warranties or
disclaimers, or any copyright, trademark, patent or other intellectual property or proprietary rights
notices from any Services, including any copy thereof;
                        </div>
                        <div>
                        access or use the Services in any manner or for any purpose that infringes, misappropriates or
otherwise violates any intellectual property right or other right of any third party or that violates any
applicable law;
                        </div>
                        <div>
                        send spam or otherwise duplicative or unsolicited messages in violation of applicable laws;
                        </div>
                        <div>
                        send or store infringing, obscene, threatening, libelous, or otherwise unlawful, unsafe,
                        malicious, abusive or tortious material, including material harmful to children or violative of thirdparty privacy rights;
                        </div>
                        <div>
                        send or store material containing software viruses, worms, trojan horses or other harmful
                        computer code, files, scripts, agents or programs;
                        </div>
                        <div>
                        attempt to gain unauthorized access to the Service or its related systems or networks;
                        </div>
                        <div>
                        use the Services in any way that violates any applicable federal, state, local or international
law or regulation (including, without limitation, any laws regarding the export of data or software to
and from the US or other countries);
                        </div>
                        <div>
                        use the Services for the purpose of exploiting, harming or attempting to exploit or harm
minors in any way by exposing them to inappropriate content, asking for personally identifiable
information or otherwise;
                        </div>
                        <div>
                        engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the
Services, the Hiyllo WorkSpace Platform, or which, as determined by Hiyllo, may harm Hiyllo or
users of the Hiyllo WorkSpace Platform or Services or expose them to liability;
                        </div>
                        <div>
                        use any device, software or routine that interferes with the proper working of the Services or
                        the Hiyllo WorkSpace Platform;
                        </div>
                        <div>
                        access or use the Services for purposes of competitive analysis of the Services, the
development, provision or use of a competing software service or product or any other purpose that is
to Hiyllo’s detriment or commercial disadvantage; or
                        </div>
                        <div>
                        otherwise access or use the Services beyond the scope of the authorization granted under this
                        Agreement.
                        </div>
                    </Regal.OL>
                </Regal.Content>
                <Regal.Content title="Service Management">
                Customer shall, throughout the Term, maintain within its organization a
service manager to serve as the primary point of contact for day-to-day communications, consultation
and decision-making regarding the Services. Each service manager shall be responsible for providing
all day-to-day consents and approvals on behalf of such party under this Agreement.
                </Regal.Content>
                <Regal.Content title="Customer Obligations">
                In addition to any other provision of this Agreement, Customer shall: (i)
have responsibility for the accuracy, quality, legality, reliability, and appropriateness of all Customer
Materials (as defined in <Regal.Ref to="Customer Intellecutal Property License"/>); (ii) timely supply Hiyllo with all necessary information and
resources including, without limitation, all files, materials, data necessary for the Services, and access
to information, personnel, systems and facilities, reasonably requested by Hiyllo (and Hiyllo shall be
entitled to rely upon any instructions, guidelines or information provided to Hiyllo by Customer); (iii)
use commercially reasonable efforts to prevent unauthorized control or tampering or any other
unauthorized access to, or use of, the Services; (iv) comply with all applicable laws (including laws
regarding privacy and protection of consumer information) in using the Service; (v) obtain and
maintain all computer hardware, software and communications equipment needed to access the
Services; (vi) retain sole control over the operation, maintenance, and management of, and all access
to and use of, the Customer systems, and sole responsibility for all access to and use of the Services
                </Regal.Content>
                <Regal.Content title="Suspension or Termination of Services">
                Hiyllo may, directly or indirectly, through disabling
technology or other legal means, suspend, terminate, or otherwise deny Customer’s, any Authorized
User’s, or any other person’s access to or use of all or any part of the Services, without incurring any
resulting obligation or liability, if: (a) Hiyllo receives a judicial or other governmental demand or
order, subpoena, or law enforcement request that expressly or by reasonable implication requires
Hiyllo to do so; or (b) Hiyllo believes, in its discretion, that: (i) Customer or any Authorized User has
failed to comply with any term of this Agreement, or accessed or used the Services beyond the scope
of the rights granted or for a purpose not authorized under this Agreement or in any manner that does
not comply with any instruction or requirement of Hiyllo; (ii) Customer or any Authorized User is,
has been, or is likely to be involved in any fraudulent, misleading, or unlawful activities relating to or
in connection with any of the Services; or (iii) this Agreement expires or is terminated. This Section
does not limit any of Hiyllo’s other rights or remedies, whether at law, in equity, or under this
Agreement.
                </Regal.Content>
            </Regal.Content>
            <Regal.Content title="Data Backup">
            The Services do not replace the need for Customer to maintain regular data backups or
redundant data archives. Hiyllo HAS NO OBLIGATION OR LIABILITY FOR ANY LOSS,
ALTERATION, DESTRUCTION, DAMAGE, CORRUPTION OR RECOVERY OF CUSTOMER
DATA.
            </Regal.Content>
            <Regal.Content title="Security">
                <Regal.Content title="Customer Control and Responsibility">
                    Customer has and will retain sole responsibility for:
                    <Regal.Content>
                        all Customer Data, including its content and use;
                    </Regal.Content>
                    <Regal.Content>
                        all information, instructions and materials provided by or on behalf of Customer or any Authorized User in connection with the Services;
                    </Regal.Content>
                    <Regal.Content>
                    Customer’s information technology infrastructure, including computers, software, databases,
    electronic systems (including database management systems) and networks, whether operated directly
    by Customer or through the use of third-party services (“Customer Systems”);
                    </Regal.Content>
                    <Regal.Content>
                    the security and use
                    of Customer’s and its Authorized Users’ access credentials; and
                    </Regal.Content>
                    <Regal.Content>
                    all access to and use of the
Services directly or indirectly by or through the Customer Systems or its or its Authorized Users’
access credentials, with or without Customer’s knowledge or consent, including all results obtained
from, and all conclusions, decisions and actions based on, such access or use. “Customer Data” means
information, data, and other content, in any form or medium, that is collected, downloaded, or
otherwise received, directly or indirectly from Customer or an Authorized User by or through the
Services or that incorporates or is derived from the processing of such information, data, or content
by or through the Services.
                    </Regal.Content>
                </Regal.Content>
                <Regal.Content title="Access and Security">
                    Customer shall employ all physical, administrative and technical controls,
screening and security procedures and other safeguards necessary to: (a) securely administer the
distribution and use of all access credentials and protect against any unauthorized access to or use of
the Services; and (b) control the content and use of Customer Data, including the uploading or other
provision of Customer Data for processing by the Services.
                </Regal.Content>
            </Regal.Content>
            <Regal.Content title="Fess; Payment Terms">
                <Regal.Content title="Fees">
                    Customer shall pay Hiyllo the fees and expenses set forth in the applicable Customer Order.
                    All Fees are non-refundable.
                </Regal.Content>
                <Regal.Content title="Taxes">
                    All Fees and other amounts payable by Customer under this Agreement are exclusive of
taxes and similar assessments. Customer is responsible for all sales, use and excise taxes, and any
other similar taxes, duties and charges of any kind imposed by any federal, state or local
governmental or regulatory authority on any amounts payable by Customer hereunder, other than any
taxes imposed on Hiyllo’s income.
                </Regal.Content>
                <Regal.Content title="Payment">
                    <Regal.OL>
                        <div>
                            <b>Form of Payment.</b> A valid payment method, including credit card, is required to process the
payment for your Services. You shall provide Hiyllo with accurate and complete billing information
including full name, address, state, zip code, telephone number, EIN, and valid payment method
information. By submitting such payment information, you automatically authorize Hiyllo to charge
all Fees incurred through your account to any such payment instruments. Should automatic billing fail
to occur for any reason, Hiyllo will issue an electronic invoice indicating that you must proceed
manually, within a certain deadline date, with the full payment corresponding to the billing period as
indicated on the invoice. Except as otherwise agreed, Customer must create an account with
Hiyllo’s third party subcontractor Stripe, Inc. (stripe.com). Payment of Fees will be coordinated
through Stripe Inc. Stripe, Inc. Hiyllo responsible for the relationship and agreements Customer
enters with Stripe, Inc.
                        </div>
                        <div>
                            <b>Auto Renewal.</b> If you do not cancel any subscription Services prior to the end of the
subscription Services term, the subscription Services will automatically renew at the current Hiyllo
monthly rate and be automatically billed and charged to you using the payment method originally
used to purchase the subscription Services. Hiyllo will provide you with no fewer than 14 calendar
days notice of any change in subscription Fees to give you an opportunity to terminate your
subscription Services before such change becomes effective. Your continued use of the subscription
Services after the fee change comes into effect constitutes your agreement to pay the modified
subscription Services fee amount.
                        </div>
                        <div>
                            <b>Authorized Users.</b>  Fees based on number of Authorized Users will be based on the greater of
(1) the maximum number of Authorized Users maintained for a minimum duration of 1
hour during the billing cycle, or (2) the minimum number of Authorized users as set forth in the
Customer Order.
                        </div>
                        <div>
                            <b>File Storage Fees.</b> File storage fees will be billed based upon peak amount of storage used
during the applicable billing period. After deduction of any free storage provided as part of the
Customer Order, file storage fees will be billed in 100 gigabyte increments (i.e. in the event that the
total amount of storage used after any free amount is deducted is greater than zero (0) gigabytes bu
                        </div>
                        <div>
                            <b>Billing.</b> On the last day of every calendar month, Customer will receive an invoice accessible via www.hiyllo.business and their payment method on file will be automatically debited based on the terms of their subscription
                        </div>
                        <div>
                            <b>First Month Pro Rating.</b> Upon execution of a Customer Order, an initial invoice will be
created immediately, and a pro-rated charge for Fees shall be assessed based on the number of initial
Authorized Users purchased by Customer.
                        </div>
                        <div>
                            <b>Calculation of Pro-Rated Charges</b> Pro-Rated charges are calculated based on: (i) the
number of days between the execution date of a pro-rating and the next 28th day of the month,
referred to as remaining cycle days, and (ii) the base subscription rate divided by 28, referred to as the
daily base subscription rate. The pro-rated fee for each additional Authorized User shall be the daily
base subscription rate multiplied by the remaining cycle days.
                        </div>
                        <div>
                            <b>Increase in Authorized Users During a Billing Cycle.</b> In the event that the number of
Authorized Users registered to the Hiyllo WorkSpace Platform exceeds the number of Authorized
Users Customer was billed for in their most recent invoice, Customer will automatically be charged a
pro-rated rate for the additional Authorized User. This charge will be represented on the following
billing cycle’s invoice, however Hiyllo reserves the right to automatically debit this Fee from
Customer’s payment method on file without notice before the billing cycle is complete.
                        </div>
                        <div>
                            <b>Increase in File Storage Usage During a Billing Cycle.</b> In the event that the amount of file storage used exceeds the amount of free storage provided as part of the Customer Order, or the amount of pre-paid storage included in the charge for the current month; Hiyllo reserves the right to automatically charge Customer a pro-rated amount equal to the file storage pricing under the Customer&apos;s plan divided by the 28 multipled by the number of remaining calendar days before the next billing date. If Hiyllo chooses not to exercise its right to collect this amount, the total amount owed shall be considered waived.
                        </div>
                    </Regal.OL>
                </Regal.Content>
                <Regal.Content title="Late Payment">
                    Should Customer fail to make timely payments pursuant to the Agreement, Hiyllo
may suspend further performance of any or all Services. If Customer fails to pay any due and payable
amounts within 10 calendar days after receipt of Hiyllo’s written notice that such amounts are past
due, Customer shall be deemed to be in material breach of this Agreement.
                </Regal.Content>
                <Regal.Content title="Change of Fee Structure or Pricing">
                    Hiyllo reserves the right to make changes to the fee structure or pricing at any time, unless otherwise noted in a separate written agreement. In such an event, Hiyllo shall provide written notice to the customer no fewer than 30 calendar days before the date of automatic charge on which the new fee structure or pricing will take effect.
                </Regal.Content>
            </Regal.Content>
            <Regal.Content title='Intellectual Property Rights'>
                <Regal.Content title="Services and Hiyllo Materials/Customer Content">
                    <Regal.OL>
                        <div>
                        All right, title and interest in and to the Hiyllo Materials, including all Intellectual Property
Rights therein, are and will remain with Hiyllo and, with respect to third-party materials included as
part of Third-Party Services, the applicable Third-Party providers own all right, title, and interest,
including all Intellectual Property Rights, in and to the third-party materials. Customer has no right,
license, or authorization with respect to any of the Hiyllo Materials except as expressly set forth in
this Agreement. All other rights in and to the Hiyllo Materials are expressly reserved by Hiyllo.
“Hiyllo Materials” means the Services, the Hiyllo WorkSpace Platform, specifications,
documentation, and Hiyllo systems and any and all other information, data, documents, materials,
works, and other content, devices, methods, processes, hardware, software, and other technologies
and inventions, including any deliverables, work product, technical or functional descriptions,
requirements, plans, or reports, that are provided, created or used in connection with the Services or
otherwise comprise or relate to the Services, including all data collected by the Hiyllo WorkSpace
Platform and Services.
                        </div>
                        <div>
                            Notwithstanding the above, Hiyllo Materials shall not include any text, images, graphic
designs, audio, video or other proprietary content uploaded into, posted on, inserted into, or otherwise
made available on the Hiyllo WorkSpace Platform (“Customer Content”). To the extent Hiyllo obtains
any rights to the Customer Content, Hiyllo hereby assigns to Customer all right, title and interest in
and to the Customer Content. To the extent Customer obtains any rights to the Hiyllo Materials,
Customer hereby assigns to Hiyllo all right, title and interest in and to Hiyllo Materials. “Intellectual
Property Rights” means any and all registered and unregistered rights granted, applied for, or
otherwise now or hereafter in existence under or related to any patent, copyright, trademark, trade
secret, database protection, or other intellectual property rights laws, and all similar or equivalent
rights or forms of protection, in any part of the world.
                        </div>
                    </Regal.OL>
                </Regal.Content>
                <Regal.Content title="Customer Intellecutal Property License">
                    Customer grants to Hiyllo a limited, non-exclusive,
royalty-free right and license during the Term, with the right to use the Customer Materials as
necessary to fulfill Hiyllo’s obligations under this Agreement, solely for the purpose of providing the
Services. “Customer Materials” means trademarks, trade names, service marks, trade dress, designs,
artwork and logos of Customer associated with the Services, all images, data and other materials
provided by the Customer for the purposes of this Agreement, and all Customer Content and other
content provided by Customer for posting on the Hiyllo WorkSpace Platform or through the Services.
                </Regal.Content>
                <Regal.Content title="Customer Feedback">
                    If Customer or any Authorized User transmits or provides any communications or
materials to Hiyllo by mail, email, telephone, or otherwise, suggesting or recommending changes to
the Services or Hiyllo WorkSpace Platform (“Feedback”), Hiyllo is free to use such Feedback
irrespective of any other obligation or limitation between the parties governing such Feedback.
Customer hereby assigns to Hiyllo on Customer’s behalf, and on behalf of its employees, contractors
and/or agents, all right, title, and interest in, and Hiyllo is free to use, without any attribution or
compensation to any party, any ideas, know-how, concepts, techniques, or other intellectual property
rights contained in the Feedback, for any purpose whatsoever, although Hiyllo is not required to use
any Feedback.
                </Regal.Content>
            </Regal.Content>
            <Regal.Content title="Publicity">
            Customer agrees that Hiyllo may use Customer’s business name, website URL and logo on
its websites and in its marketing materials, identifying Customer as a current or former customer of
Hiyllo.
            </Regal.Content>
            <Regal.Content title="Support">
            Any supplementary agreements the Customer has with Hiyllo not withstanding, Hiyllo will
            provide support via email, with a response time of no more than 24 hours.
            </Regal.Content>
            <Regal.Content title="Confidentiality">
            Each Party shall (a) use Confidential Information (as defined below) of the other
Party only for the purposes of exercising rights or performing obligations in connection with this
Agreement; and (b) use at least reasonable care to protect from disclosure to any third parties any
Confidential Information disclosed by the other Party (including without limitation all precautions
employed by a Party with respect to its own Confidential Information), in each case, for a period
commencing upon the date of disclosure and continuing thereafter. Notwithstanding the foregoing,
either Party may disclose Confidential Information (a) to its employees or independent contractors for
the purpose of performing its obligations or exercising its rights hereunder, provided that each such
employee and independent contractor is subject to a written agreement that includes binding use and
disclosure restrictions that are at least as protective as those set forth herein; and (b) if required by law
or a court, provided the receiving Party uses reasonable efforts to limit disclosure and to obtain
confidential treatment or a protective order and allows the disclosing Party to participate in the
proceeding. “Confidential Information” means and includes the terms of this Agreement and all
confidential and proprietary information of a Party, including, without limitation, computer programs,
software, technical drawings, algorithms, know-how, trade secrets, formulas, processes, ideas,
inventions (whether patentable or not), designs, schematics and other technical, business, financial,
customer and product information, data and development plans, of any nature and in any form
whatsoever, provided that such information is marked or designated in writing as “confidential,”
“proprietary,” or any other similar term or designation or is disclosed in such a manner that a
reasonable person would understand that such information is confidential and proprietary.
Confidential Information does not include information that the receiving Party can document: (i) is or
becomes (through no improper action or inaction by the receiving Party) readily available to the
public; (ii) was in its possession or known by it without restriction on disclosure or use prior to
receipt from the disclosing Party; (iii) was rightfully disclosed to it by a third party that rightfully
received such information without restriction on disclosure or use; or (iv) was independently
developed without use of any Confidential Information of the disclosing Party
            </Regal.Content>
            <Regal.Content title="Term and Termination">
                <Regal.Content title="Term">
                Unless earlier terminated in accordance with this Agreement, this Agreement will continue
in effect for the period set forth in the applicable Customer Order as extended pursuant to the Terms
of Service.
                </Regal.Content>
                <Regal.Content title="Termination">
                In addition to any other express termination right set forth elsewhere in this
                Agreement:
                <Regal.OL>
                    <div>either party may terminate this Agreement or Customer Order effective on written notice to
the other Party, if the other Party materially breaches this Agreement or the applicable Customer
Order, and such breach: (i) is incapable of cure; or (ii) being capable of cure, remains uncured ninety
(90) days (ten (10) days for any failure to pay amounts due) after the non-breaching party provides
the breaching party with written notice of such breach; and</div>
<div>
either Party may terminate this Agreement (and all Customer Orders), effective immediately
upon written notice to the other Party, if the other party: (i) becomes insolvent or is generally unable
to pay, or fails to pay, its debts as they become due; (ii) files or has filed against it, a petition for
voluntary or involuntary bankruptcy or otherwise becomes subject, voluntarily or involuntarily, to any
proceeding under any domestic or foreign bankruptcy or insolvency law; (iii) makes or seeks to make
a general assignment for the benefit of its creditors; or (iv) applies for or has appointed a receiver,
trustee, custodian or similar agent appointed by order of any court of competent jurisdiction to take
charge of or sell any material portion of its property or business.
</div>
<div>
Either Party may otherwise provide written notice to the other Party to terminate the Services
at the end of the then current term.
</div>
<div>
When termination notice is given by either Party, Hiyllo will make all the Customer Data
contained within the Hiyllo WorkSpace Platform available to Customer for export or download within
7 days, and will maintain this availability for no fewer than 30 days after the notice of termination is
delivered or the date of termination, after which Hiyllo shall delete the data from its systems. The
services will no longer be available to Customer on the effective date of termination. Hiyllo reserves
the right to withhold export or provision of Customer Data until Customer has paid all outstanding
Fees.
</div>
<div>
Customer may notify Hiyllo of termination via an email to omni-customer@hiyllo.com from
its primary email address. Hiyllo may notify Customer of termination via an email to the email address
set forth in the Customer Order.
</div>
<div>
Hiyllo may charge Customer for any outstanding Fees as of the date of termination.
</div>
                </Regal.OL>
                </Regal.Content>
                <Regal.Content title="Surviving Terms">
                    Any provision or other right or obligation of the parties in this Agreement
that, by its nature, should survive termination or expiration of this Agreement, will survive any
expiration or termination of this Agreement.
                </Regal.Content>
            </Regal.Content>
            <Regal.Content title="Representations and Warranties">
                <Regal.Content title="Mutual Representations and Warranties">
                    Each Party represents and warrants to the other
Party that: (a) it is duly organized, validly existing and in good standing as a corporation or other
entity under the laws of the jurisdiction of its incorporation or other organization; (b) it has the full
right, power and authority to enter into and perform its obligations and grant the rights, licenses,
consents and authorizations it grants or is required to grant under this Agreement; (c) the execution
of this Agreement by its representative whose signature is set forth at the end of this Agreement has
been duly authorized by all necessary corporate or organizational action of such party; and (d) when
executed and delivered by both parties, this Agreement will constitute the legal, valid and binding
obligation of such party, enforceable against such party in accordance with its terms.
                </Regal.Content>
                <Regal.Content title="Additional Customer Representations, Warranties and Covenants">
                    Customer represents,
warrants and covenants to Hiyllo that Customer owns or otherwise has and will have the necessary
rights and consents in and relating to the Customer Materials so that, as received by Hiyllo and
processed in accordance with this Agreement, they do not and will not infringe, misappropriate or
otherwise violate any Intellectual Property Rights, or any privacy or other rights of any third party or
violate any applicable law, and that it will comply with all terms and conditions of any agreement
between Customer and the provider of Third-Party Services.
                </Regal.Content>
                <Regal.Content title="DISCLAIMER OF WARRANTIES">
                EXCEPT FOR THE EXPRESS WARRANTIES SET
FORTH IN <Regal.Ref to="Mutual Representations and Warranties"/>, ALL SERVICES AND HIYLLO MATERIALS ARE PROVIDED ON
AN “AS IS” “WHERE IS” BASIS, AND HIYLLO DISCLAIMS ALL REPRESENTATIONS OR
WARRANTIES, EXPRESS OR IMPLIED, AS TO THE SERVICES OR HIYLLO MATERIALS.
HIYLLO SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF
MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NONINFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE
OR TRADE PRACTICE. WITHOUT LIMITING THE FOREGOING, HIYLLO MAKES NO
WARRANTY OF ANY KIND THAT THE SERVICES OR HIYLLO MATERIALS, OR ANY
PRODUCTS OR RESULTS OF THE USE THEREOF, WILL MEET CUSTOMER’S OR ANY
OTHER PERSON’S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY
INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM OR
OTHER SERVICES, OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE
OR ERROR FREE. ALL THIRD-PARTY SERVICES AND MATERIALS ARE PROVIDED “AS
IS”, AND ANY REPRESENTATION OR WARRANTY OF OR CONCERNING ANY THIRDPARTY SERVICES OR MATERIALS IS STRICTLY BETWEEN CUSTOMER AND THE THIRDPARTY OWNER OR DISTRIBUTOR OF THE THIRD-PARTY SERVICES AND MATERIALS.
                </Regal.Content>
            </Regal.Content>
            <Regal.Content title='Customer Indemnification'>
            Customer shall indemnify, defend and hold harmless Hiyllo and its
officers, directors, employees, agents, stockholders, successors and assigns (each, a “Hiyllo
Indemnitee”) from and against any and all losses, damages, deficiencies, claims, actions, judgments,
settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including
reasonable attorneys’ fees incurred by such Hiyllo Indemnitee in connection with any arising out of or
relating to any claim, suit, action or proceeding by a third party that arises out of or relates to any: (a)
Customer Materials, including any processing of Customer Materials by or on behalf of Hiyllo in
accordance with this Agreement; (b) use of any Third Party Services, (c) allegation of facts that, if
true, would constitute Customer’s breach of any of its representations, warranties, covenants or
obligations under this Agreement; (d) negligence or more culpable act or omission (including
recklessness or willful misconduct) by Customer, any Authorized User, or any third party on behalf of
Customer or any Authorized User, in connection with this Agreement; or (e) Customer’s violation or
alleged violation of applicable law.
            </Regal.Content>
            <Regal.Content title="Limitation of Liability">
                <Regal.Content title="EXCLUSION OF DAMAGES">
                    IN NO EVENT WILL HIYLLO OR ANY OF ITS
LICENSORS, SERVICE PROVIDERS OR SUPPLIERS BE LIABLE UNDER OR IN
CONNECTION WITH THIS AGREEMENT OR ITS SUBJECT MATTER UNDER ANY LEGAL
OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING
NEGLIGENCE), STRICT LIABILITY AND OTHERWISE, FOR ANY: (A) LOSS OF
PRODUCTION, USE, BUSINESS, REVENUE OR PROFIT; (B) IMPAIRMENT, INABILITY TO
USE OR LOSS, INTERRUPTION OR DELAY OF THE SERVICES, (C) LOSS, DAMAGE,
CORRUPTION OR RECOVERY OF DATA, OR BREACH OF DATA OR SYSTEM SECURITY,
OR (D) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED
OR PUNITIVE DAMAGES, REGARDLESS OF WHETHER SUCH PERSONS WERE ADVISED
OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES
WERE OTHERWISE FORESEEABLE, AND NOTWITHSTANDING THE FAILURE OF ANY
AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.
                </Regal.Content>
                <Regal.Content title="CAP ON MONETARY LIABILITY">
                    IN NO EVENT WILL THE AGGREGATE LIABILITY
OF HIYLLO UNDER OR IN CONNECTION WITH THIS AGREEMENT OR ITS SUBJECT
MATTER, UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF
CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY AND OTHERWISE,
EXCEED THE AMOUNT PAID BY CUSTOMER TO HIYLLO IN THE 3 MONTHS
IMMEDIATELY PRIOR TO THE ACTION OR CLAIM. THE FOREGOING LIMITATION
APPLIES NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF
ITS ESSENTIAL PURPOSE.
                </Regal.Content>
            </Regal.Content>
            <Regal.Content title='Force Majeure'>
                Neither Party shall be liable for any default or delay in the performance of any of its
obligations under this Agreement if and to the extent such default or delay is caused, directly or
indirectly, by (a) fires, floods, earthquakes, hurricanes, epidemics, pandemics, elements of nature or
acts of God; (b) acts of war (declared and undeclared), acts of terrorism, sabotage, strikes or other
labor disputes, riots or other acts of civil disorders, rebellions or revolutions; (c) extraordinary
malfunction of third-party Internet infrastructure, data centers or related systems or (d) acts of any
governmental authority with respect to any of the foregoing or other acts such as expropriation,
condemnation, embargo, changes in laws, and shelter-in-place or similar orders,, and provided that
such default or delay cannot reasonably be circumvented by the non-performing Party through the use
of commercially reasonable alternate sources, workaround plans or other commercially reasonable
means.
            </Regal.Content>
            <Regal.Content title='Miscellaneous'>
                <Regal.Content title='Relationship of the Parties'>
                    The relationship between the parties is that of independent
contractors. Nothing contained in this Agreement shall be construed as creating any agency,
partnership, joint venture or other form of joint enterprise, employment or fiduciary relationship
between the parties, and neither party shall have authority to contract for or bind the other party in
any manner whatsoever.
                </Regal.Content>
                <Regal.Content title='Notices'>
                Any notice to be given under this Agreement shall be in writing and delivered by hand
U.S. mail or electronic mail to the addresses set forth in this Agreement for Hiyllo or as set forth in
the Customer Order for Customer. Notices sent in accordance with this Section will be deemed
effectively given: (a) when received, if delivered by hand, with signed confirmation of receipt; (b)
when received, if sent by a nationally recognized overnight courier, signature required; (c) when
sent, if by e-mail, (in each case, with confirmation of transmission), if sent during the addressee’s
normal business hours, and on the next business day, if sent after the addressee’s normal business
hours; and (d) on the 3rd day after the date mailed by certified or registered mail, return receipt
requested, postage prepaid.
                </Regal.Content>
                <Regal.Content title="Headings">
                The headings in this Agreement are for reference only and do not affect the
interpretation of this Agreement.
                </Regal.Content>
                <Regal.Content title="Entire Agreement">
                This Agreement, together with the Customer Order and any other documents
incorporated herein by reference, constitutes the sole and entire agreement of the Parties with respect
to the subject matter of this Agreement and supersedes all prior and contemporaneous
understandings, agreements, representations and warranties, both written and oral, with respect to
such subject matter.
                </Regal.Content>
                <Regal.Content title="Assignment">
                Neither Party may assign this Agreement, whether voluntarily, involuntarily, by
merger, consolidation, dissolution, operation of law, or in any other manner, without the prior written
consent of the other Party; provided, however, that, notwithstanding the foregoing, Hiyllo may
assign this Agreement to (i) any affiliate or (ii) a third party by way of merger, acquisition,
consolidation or sale or transfer of all or substantially all of its assets or membership units. Any
purported assignment of rights in violation of this Section is void.
                </Regal.Content>
                <Regal.Content title="No Third-Party Beneficiaries">
                This Agreement is for the sole benefit of the parties hereto and
their respective permitted successors and permitted assigns and nothing herein, express or implied, is
intended to or shall confer upon any other person any legal or equitable right, benefit or remedy of
any nature whatsoever under or by reason of this Agreement.
                </Regal.Content>
                <Regal.Content title="Amendment and Modification; Waiver">
                Except as otherwise provided, no waiver or
modification of this Agreement or any Customer Order will be binding upon either Party unless
made in writing and signed by duly authorized representatives of such Party, and no failure or delay
enforcing any right will be deemed a waiver of such right.
                </Regal.Content>
                <Regal.Content title="Severability">
                If any provision of this Agreement is invalid, illegal or unenforceable in any
jurisdiction, such invalidity, illegality or unenforceability shall not affect any other term or provision
of this Agreement or invalidate or render unenforceable such term or provision in any other
jurisdiction. Upon such determination that any term or other provision is invalid, illegal or
unenforceable, the parties hereto shall negotiate in good faith to modify this Agreement so as to
effect the original intent of the parties as closely as possible in a mutually acceptable manner in order
that the transactions contemplated hereby be consummated as originally contemplated to the greatest
extent possible.
                </Regal.Content>
                <Regal.Content title="Governing Law">
                    All matters relating to the Services, the Hiyllo WorkSpace Platform and this
Agreement and any dispute or claim arising therefrom or related thereto (in each case, including
non-contractual disputes or claims), shall be governed by and construed in accordance with the
internal laws of the State of New Jersey without giving effect to any choice or conflict of law
provision or rule (whether of the State of New Jersey or any other jurisdiction).
                </Regal.Content>
                <Regal.Content title="Arbitration">
                    <Regal.OL>
                        <div>
                        <b>Scope, Governing Rules.</b> The Parties agree that any controversy or claim arising out of or
relating to this Agreement, or the breach thereof, shall exclusively be determined by final and binding
arbitration administered by the American Arbitration Association (“AAA”) under its Commercial
Arbitration Rules and Mediation Procedures (“Commercial Rules”).
                        </div>
                        <div>
                        <b>Authority of Tribunal, Judicial Review.</b> The award rendered by the arbitrator shall be final
and non-appealable and binding on the Parties and may be entered and enforced in any court having
jurisdiction.
                        </div>
                        <div>
                        <b>Selection of Tribunal.</b> There shall be one arbitrator agreed to by the parties within twenty
(20) days of receipt by respondent of the request for arbitration or in default thereof appointed by the
AAA in accordance with its Commercial Rules.
                        </div>
                        <div>
                        <b>Seat of Arbitration.</b> The seat or place of arbitration shall be Mercer County, New Jersey, and
                        the Parties waive any objection to arbitration taking place in Mercer County
                        </div>
                    </Regal.OL>
                </Regal.Content>
                <Regal.Content title="Attorneys’ Fees">
                In the event that any arbitration or any other action, suit, or other legal or
administrative proceeding is instituted or commenced by either Party against the other party arising
out of this Agreement, the prevailing Party shall be entitled to recover its reasonable attorneys’ fees
and costs from the non-prevailing Party.
                </Regal.Content>
            </Regal.Content>
        </Regal.Root>
    );
});
