import { faFileUpload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NymblIcon } from '@hiyllo/icons/main';
import { styled } from '@hiyllo/ux/styled';
import React from 'react';
import { motion } from 'framer-motion';

const Container = styled('div', ({ $theme }) => ({
    background: $theme.background1,
    borderRadius: 10,
    boxShadow: 'rgba(0, 0, 0, 1) 0px 0px 20px 20px',
    width: 300,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
    pointerEvents: 'none'
}));

const InputBar = styled('div', ({ $theme }) => ({
    background: 'rgb(40,40,44)'
}));

const Separator = styled('div', ({ $theme }) => ({
    height: 1,
    background: $theme.midground1
}));

const Input = styled('input', ({ $theme }) => ({
    height: 60,
    width: 'calc(100% - 20px)',
    background: 'transparent',
    border: 'none',
    padding: 0,
    margin: 0,
    paddingLeft: 10,
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: $theme.foreground,
    fontFamily: 'hiyllo',
    fontSize: 17.5
}));

const Toolbar = styled('div', ({ $theme }) => ({
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
    paddingLeft: 10,
    paddingRight: 10
}));

const Avatar = styled('img', ({ $theme }) => ({
    width: 40,
    height: 40,
    borderRadius: 10,
    objectFit: 'cover'
}));

const MessageName = styled('div', ({ $theme }) => ({
    color: $theme.foreground,
    fontFamily: 'hiyllo',
    fontSize: 15,
    fontWeight: 'bold'
}));

const MessageText = styled('div', ({ $theme }) => ({
    color: $theme.foreground,
    fontFamily: 'hiyllo',
    fontSize: 15
    // fontWeight: 'bold'
}));

const Message = React.memo(function Message(props: {
    userImage: string;
    name: string;
    text: string;
}): JSX.Element {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
            <Avatar src={props.userImage}/>
            <div>
                <MessageName>{props.name}</MessageName>
                <MessageText>{props.text}</MessageText>
            </div>
        </div>
    );
});

function typeOut(text: string, callback: (text: string) => void): Promise<void> {
    return new Promise((resolve) => {
        let i = 0;
        const interval = setInterval(() => {
            i++;
            callback(text.slice(0, i));
            if (i >= text.length) {
                clearInterval(interval);
                resolve();
            }
        }, 80);
    });
}

export const FakeChatWindow = React.memo(function FakeChatWindow(): JSX.Element {
    const [value, setValue] = React.useState('');
    const [showNewMessage, setShowNewMessage] = React.useState(false);

    const type = React.useCallback(() => {
        setShowNewMessage(false);
        setTimeout(() => {
            void typeOut('Awesome, thanks for the update!', (text) => {
                setValue(text);
            }).then(() => {
                setTimeout(() => {
                    setShowNewMessage(true);
                    setValue('');
                    setTimeout(type, 5000);
                }, 200);
            });
        }, 2500);
    }, []);

    React.useEffect(() => {
        setTimeout(type, 2000);
    }, []);

    return (
      <Container>
        <div style={{ flexGrow: 1, height: 5 }}/>
        <div style={{ padding: 10, display: 'flex', flexDirection: 'column', gap: 10 }}>
            <Message
                userImage="/jimmy-fermin-bqe0J0b26RQ-unsplash.jpg"
                name='Jenny Fermin'
                text={'Hey, what are you working on?'}
            />
            <Message
                userImage="/alexander-hipp-iEEBWgY_6lA-unsplash.jpg"
                name="Fermi P'dox"
                text={'Gonna start with the 3D video support, then cloud stuff.'}
            />
            <motion.div
                animate={{
                    height: showNewMessage ? '' : 0,
                    translateY: showNewMessage ? 0 : 10
                }}
                transition={{
                    duration: 0.3
                }}
                style={{
                    overflow: 'hidden'
                }}
            >
                <Message
                    userImage="/jimmy-fermin-bqe0J0b26RQ-unsplash.jpg"
                    name='Jenny Fermin'
                    text={'Awesome, thanks for the update!'}
                />
            </motion.div>
        </div>
        <InputBar>
            <Toolbar>
                <NymblIcon width={15} color='white'/>
                <FontAwesomeIcon icon={faFileUpload} fontSize={15} color="white"/>
            </Toolbar>
            <Separator/>
            <Input
                value={value}
                disabled
            />
        </InputBar>
      </Container>
    );
});
