import React from 'react';
import { LoadingPage } from '@hiyllo/ux/standard-loader';

import { seamlessClient } from '../../seamless';
import * as ProcessLoginBP from '../../blueprints/auth/process-login';

export const HiylloAuthSSO = React.memo(function HiylloAuthSSO (): JSX.Element {
  const processLoginMutation = seamlessClient.useMutation<ProcessLoginBP.Plug>(ProcessLoginBP);

  React.useEffect(() => {
    console.log('[SSO] Effect Entered');
    const usp = new window.URLSearchParams(window.location.search);

    if (window.location.pathname === '/iauth' && usp.get('token') != null) {
      void processLoginMutation.call({
        token: usp.get('token') ?? ''
      }).then(({ token, isEmployee }) => {
        if (isEmployee) {
          window.sessionStorage.employee = '1';
        }
        window.sessionStorage.setItem('token', token);
        const path = usp.get('then') ?? '';
        window.history.pushState({}, '', path === '/sso' ? '/' : path);
        window.location.reload();
      });
    } else {
      if (window.sessionStorage.token == null) {
        console.log('[SSO] Requesting Login');

        if (window.location.host.endsWith('.hiyllo.business')) {
          const federation = window.location.host.split('.')[0] ?? null;
          if (federation != null) {
            window.location.href = `https://${federation}.f.hiyllo.cloud/ia/${window.location.host}?then=${encodeURIComponent(window.location.pathname + window.location.search)}`;
          } else {
            window.location.href = `https://www.hiyllo.cloud/ia/${window.location.host}?then=${encodeURIComponent(window.location.pathname + window.location.search)}`;
          }
        } else {
          window.location.href = `https://www.hiyllo.cloud/f/create?then=${encodeURIComponent(`https://www.hiyllo.cloud/ia/${window.location.host === 'localhost:3000' ? 'localhost:3000' : '<tenant>.hiyllo.business'}?then=${encodeURIComponent(window.location.pathname + window.location.search)}`)}`;
        }
      }
    }
  }, []);

  return (
    <div style={{ height: '100vh' }}>
      <LoadingPage/>
    </div>
  );
});
