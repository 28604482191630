/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { PromotionCodeType } from "../../types/promotion-code";

export type ParamsType = {
    code: string;
};
export type ResponseType = {
    promotion: PromotionCodeType
};
export const Endpoint = 'promotions/get-promotion';
export const Method = 'GET';

export interface Plug {
    params: ParamsType
    response: ResponseType
    method: typeof Method
    endpoint: typeof Endpoint
};export const paramsSchema = {"type":"object","properties":{"code":{"type":"string"}},"required":["code"],"$schema":"http://json-schema.org/draft-07/schema#"}