/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable require-await */
import axios from 'axios';
import { MoopsyClient, MoopsyClientAuthExtension } from '@moopsyjs/react';

export const seamlessClient = new MoopsyClient({ debug: true, socketUrl: window.location.host === 'localhost:3000' ? 'http://localhost:3001' : '/', axios });
export const seamlessAuth = new MoopsyClientAuthExtension<{PublicAuthType: {federation: string, userId: string, type: 'hiyllo-employee' | 'customer'}, AuthRequestType: {token: string}}>(seamlessClient, {
  autoLoginFunction: async () => {
    const token = window.sessionStorage.token;

    if (token != null) {
      return { token };
    }

    return null;
  }
});
