/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
/**
 * [Hiyllo Business] Returns all products for my federation
 */

import { Product } from "../../types/product";

export type ParamsType = null;
export type ResponseType = {
    products: Product[];
};
export const Endpoint = 'products/get-federation-products';
export const Method = 'GET';

export interface Plug {
    params: ParamsType
    response: ResponseType
    method: typeof Method
    endpoint: typeof Endpoint
};export const paramsSchema = {"type":"null","$schema":"http://json-schema.org/draft-07/schema#"}