import React from 'react';
import { styled } from '@hiyllo/ux/styled';

import { Product, ProductKind } from '../../../types/product';

const Badge = styled('div', ({ $theme }) => ({
    background: $theme.midground1,
    borderRadius: 5,
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    gap: 5
}));

export const ProductBadge = React.memo(function ProductBadge({ product }: {product: Product}): JSX.Element {
    return (
        <div>
            {product.kind.type === ProductKind.work ?
                <Badge>
                    <img src="https://cdn.hiyllo.net/logo/work/icon-white.png" style={{ height: '1em' }}/>
                    Workspace
                </Badge>
            :
                <Badge>
                    <img src="https://cdn.hiyllo.net/logo/support/white-icon.png" style={{ height: '1em' }}/>
                    Support Center
                </Badge>
            }
        </div>
    );
});
