/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { PlatformKind } from "../../types/platforms";
import { ProductKind } from "../../types/product";
import { PlanKind } from "../../types/subscription";

export type ParamsType = {
    slug: string;
    kind: ProductKind;
    plan: PlanKind;
    promotionCode: string | null;
    affiliate: string | null;
};
export type ResponseType = {
    productUUID: string;
    href: string;
};
export const Endpoint = 'products/create-product';
export const Method = 'POST';

export interface Plug {
    params: ParamsType
    response: ResponseType
    method: typeof Method
    endpoint: typeof Endpoint
};export const paramsSchema = {"type":"object","properties":{"slug":{"type":"string"},"kind":{"$ref":"#/definitions/ProductKind"},"plan":{"$ref":"#/definitions/PlanKind"},"promotionCode":{"type":["null","string"]},"affiliate":{"type":["null","string"]}},"required":["affiliate","kind","plan","promotionCode","slug"],"definitions":{"ProductKind":{"enum":["support","work"],"type":"string"},"PlanKind":{"enum":["support_basic","support_internal","support_pro","work_standard"],"type":"string"}},"$schema":"http://json-schema.org/draft-07/schema#"}