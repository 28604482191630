import React from 'react';
import { seamlessClient } from '../../seamless';

import * as GetPlatformsBP from '../../blueprints/products/get-all-products';
import {
  ErrorView, GrowLoading
} from '../ux/loading-spinner';
import { PageContainer } from '../common';
import { Organization } from '../../types/organization';
import { Platform } from '../../types/platforms';
import { styled } from '@hiyllo/ux/styled';
import { SubHeader } from '../ux/typography';
import { useNavigate } from 'react-router-dom';
import { Table, TableRow } from './table';
import { Product, ProductKind, ProductStatusEnum } from '../../types/product';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';

const PlatformListItemContainer = styled('div', ({ $theme }) => ({
  background: $theme.background3,
  borderRadius: 15,
  padding: 15,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 20,
  cursor: 'pointer'
}));

const PlatformListItem = React.memo(function PlatformListItem (props: {product: Product}): JSX.Element {
  const navigate = useNavigate();

  return (
    <TableRow
      onClick={() => {
        navigate(`/products/${props.product.uuid}`);
      }}
    >
      {props.product.kind.type === ProductKind.work ?
      <img src="https://cdn.hiyllo.net/logo/work/icon-white.png" style={{
        height: 15,
        width: 20,
        objectFit: 'contain',
        paddingLeft: 5
      }}/> :
      <img src="https://cdn.hiyllo.net/logo/support/white-icon.png" style={{
        height: 15,
        width: 20,
        objectFit: 'contain',
        paddingLeft: 5
      }}/>}
      <div style={{ width: 200 }}>
        {props.product.name}
        {props.product.status === ProductStatusEnum.restricted ?
          <FontAwesomeIcon icon={faExclamationCircle}/>
        : null}
      </div>
      <div>
        Current Billable Users: {props.product.usage?.billableUsersCurrent}
      </div>
      <div>
        Storage Usage: {props.product.usage?.storageUsageCurrent} (Peak: {props.product.usage?.storageUsageGBPeak})
      </div>
    </TableRow>
  );
});

export const Admin = (): JSX.Element => {
  const organizationsQuery = seamlessClient.useQuery<GetPlatformsBP.Plug>(GetPlatformsBP, null);

  if (organizationsQuery.isLoading) {
    return <GrowLoading/>;
  }

  if (organizationsQuery.isError) {
    return <ErrorView error={organizationsQuery.error}/>;
  }

  const { products } = organizationsQuery.data;

  return (
    <>
      <Table>
        {products.map(products => (
          <PlatformListItem product={products} key={products.uuid}/>
        ))}
      </Table>
    </>
  );
};
