import React from 'react';
import { seamlessClient } from '../../seamless';

import * as GetOrganizationsBP from '../../blueprints/organizations/get-my-organizations';
import * as GetFederationProductsBP from '../../blueprints/products/get-federation-products';
import * as GetPlatformsForOrganizationsBP from '../../blueprints/platforms/get-platforms-for-organizations';
import {
  ErrorView, GrowLoading
} from '../ux/loading-spinner';
import { PageContainer, PageWithNavbar } from '../common';
import { Organization } from '../../types/organization';
import { Platform } from '../../types/platforms';
import { styled } from '@hiyllo/ux/styled';

import { SubHeader } from '../ux/typography';
import { useNavigate } from 'react-router-dom';
import { Button } from '../ux/button';
import { Subscribe } from './subscribe';
import { LoadingSpinner } from '@hiyllo/ux/loading-spinner';
import { Card } from '@hiyllo/ux/surface';
import { Typography } from '@hiyllo/ux/typography';
import { CircleButton } from '@hiyllo/ux/circle-button';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';

const PlatformListItemContainer = styled('div', ({ $theme }) => ({
  background: $theme.background3,
  borderRadius: 15,
  padding: 15,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 20,
  cursor: 'pointer'
}));

const PlatformListItem = React.memo(function PlatformListItem (props: {platform: Platform}): JSX.Element {
  const navigate = useNavigate();

  return (
    <PlatformListItemContainer
      onClick={() => {
        navigate(`/organization/${props.platform.organizationUUID}/platform/${props.platform.uuid}`);
      }}
    >
      <div style={{ height: 30 }}>
        <img src="/work/icon/white-outline.png" style={{
          height: 30,
          width: 'auto',
          paddingLeft: 5
        }}/>
      </div>
      <SubHeader>
        {props.platform.name}
      </SubHeader>
    </PlatformListItemContainer>
  );
});

const OrganizationCard = React.memo(function OrganizationCard (props: {organization: Organization}): JSX.Element {
  const getPlatformsForOrganizations = seamlessClient.useQuery<GetPlatformsForOrganizationsBP.Plug>(GetPlatformsForOrganizationsBP, { organizationUUID: props.organization.uuid });

  return (
    <div>
      {getPlatformsForOrganizations.isError
        ? <div>{getPlatformsForOrganizations.error.description}</div>
        : getPlatformsForOrganizations.isLoading
          ? <LoadingSpinner/>
          : <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 15
          }}>
            {getPlatformsForOrganizations.data.platforms.map(platform => (
              <PlatformListItem platform={platform} key={platform.uuid}/>
            ))}
          </div>
      }
    </div>
  );
});

export const Home = (): JSX.Element => {
  const productsQuery = seamlessClient.useQuery<GetFederationProductsBP.Plug>(GetFederationProductsBP, null);
  const navigate = useNavigate();

  console.log('>>> productsQuery', productsQuery);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', height: '100%', gap: 20 }}>
        <Card color="background2" style={{ width: '45%', flexGrow: 1, flexShrink: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 15 }}>
          <img src="https://cdn.hiyllo.net/logo/work/icon-gradient.png" style={{ height: 100 }} />
          <Typography.Header>Hiyllo Work</Typography.Header>
          <Typography.Paragraph>Everything your team needs in one place</Typography.Paragraph>
          <CircleButton
            icon={faArrowRight}
            onClick={() => navigate('/work')}
            size={35}
          />
        </Card>
        <Card color="background2" style={{ width: '45%', flexGrow: 1, flexShrink: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 15 }}>
          <img src="https://cdn.hiyllo.net/logo/support/color-icon.png" style={{ height: 100 }} />
          <Typography.Header>Hiyllo Support</Typography.Header>
          <Typography.Paragraph>Support that just works for everyone</Typography.Paragraph>
          <CircleButton
            icon={faArrowRight}
            onClick={() => navigate('/support')}
            size={35}
          />
        </Card>
      </div>
    </>
  );
};
